import React, { useEffect } from 'react';
import anime from 'animejs';
import logo from '../assets/images/LM Logo.png';
// import { Facebook, Instagram, Twitter } from '@material-ui/icons';
import g from '../assets/images/LM_siegel.png';
import low from '../assets/videos/bg_low.mp4';
import medium from '../assets/videos/bg_medium.mp4';
import height from '../assets/videos/bg_height.mp4';
import falg from '../assets/images/BStBK_white.png';


const Hero = () => {
  useEffect(() => {
    anime({
      targets: '#logo',
      easing: 'easeOutQuart',
      rotate: 360,
      opacity: 1,
      loop: false,
      duration: 10000
    });
    setTimeout(() => {
      document.getElementById('bg-vid').play();
    }, 100)
  }, []);
  return (
    <section id='hero'>
      <div id='main-logo'>
        <img id='logo' src={logo} alt="Lohnmüle logo"/>
      </div>
      <h2>So geht lohn Heute</h2>
      <h3>
        Wir verhelfen Ihnen als <b>Unternehmerinnen und Unternehmer</b> zu noch mehr <b>Klarheit und
        Kontrolle</b> über die Lohnabrechnung. Der <b>Wechsel</b> zur Lohnmühle ist <b>einfach</b> und wir stehen
        Ihnen bei Prüfungen zur Seite.
      </h3>
      <div className='btn-grouping'>
        <button className='btn-primary' type='button' data-target="calendar">
          Termin vereinbaren
        </button>
        <button className='btn-secondary' type='button' data-target="slogan">
          Das bieten wir
        </button>
      </div>
      <div className="bg">
        <video id="bg-vid" src={window.innerWidth > 768 ? (window.innerWidth < 1280 ? medium : height) : low} autoPlay muted loop/>
      </div>
      <img src={g} className="g" alt="Prüfungsbegleutung und Qualitätsversprechen garantiert." />
      <img src={falg} className="falg-logo" alt="Falg - Bundessteuerberaterkammer." />
      {/* <div className="socials">
        <Instagram/>
        <Facebook/>
        <Twitter/>
      </div> */}
    </section>
  )
}

export default Hero
