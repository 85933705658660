import React, { useState } from 'react';
import edtime from '../assets/images/edtime-logo.png';
import check from '../assets/images/LM_list-decoration.svg';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const Price = () => {
    const [open, setOpen] = useState(false);
    return <section id="price">
        <h3 className="caption">Preise</h3>
        <h3>
            Die <span>Grundvoraussetzung</span> ist die Nutzung von
            {' '}
            <a href="https://edtime.de" target="blank">edtime</a><span> - die clevere Zeiterfassung</span>
        </h3>
        <h2 className="title">MODERNE LOHNABRECHNUNG OHNE <span className="border-bottom">V</span>ERSTECKTE KOSTEN</h2>
        <div className="pc">
            <div className="package">
                <div className="pack">
                    <div className="pack-title">
                        <h2><span className="border-bottom">U</span>nser Lohnmühlenpaket</h2>
                    </div>
                    <div className="pack-price">
                        <h3>16,63 &euro;<span>pro Abrechnung</span></h3>
                    </div>
                    <div className="pack-details">
                        <ul>
                            <li><img src={check} className="check" alt="Windmühle"/> Monatlich kündbar</li>
                            <li><img src={check} className="check" alt="Windmühle"/> Unabhängig von der Mitarbeiteranzahl</li>
                            <li><img src={check} className="check" alt="Windmühle"/> Garantiert fester Preis</li>
                        </ul>
                    </div>
                </div>
                <div className={classnames("more-info", {'open': open})}>
                    <div className={classnames("more-pack")}>
                        <div className="info">
                            <p>Lohnabrechnung</p>
                            <h3>11,38 &euro;<span>pro Abrechnung</span></h3>
                            <ul>
                                <li><img src={check} className="check" alt="Windmühle"/> Alle Bescheinigungen sind inklusive</li>
                                <li><img src={check} className="check" alt="Windmühle"/> IDW-PS 880 zertifiziert</li>
                                <li><img src={check} className="check" alt="Windmühle"/> transparenter Preis - transparente Leistung</li>
                            </ul>
                        </div>
                        <div className="info">
                            <p>Gesetzeskonforme Ablage in der Cloud</p>
                            <h3>3,93 &euro;<span>pro Mitarbeiter</span></h3>
                            <ul>
                                <li><img src={check} className="check" alt="Windmühle"/> Wir archivieren für Sie alle personenbezogenen Unterlagen</li>
                                <li><img src={check} className="check" alt="Windmühle"/> Sie schicken uns Ihre Unterlagen rund um den Arbeitnehmer - wir machen den Rest</li>
                                <li><img src={check} className="check" alt="Windmühle"/> DSGVO konform</li>
                            </ul>
                        </div>
                        <div className="info">
                            <p>EDV Kosten Rechenzentrum</p>
                            <h3>1,32 &euro;<span>pro Mitarbeiter</span></h3>
                            <ul>
                                <li><img src={check} className="check" alt="Windmühle"/> Ihre gesamten Daten werden nach höchsten ISO Standards DSVGO komform und entsprechend der rechtlichen Anforderungen sicher im deutschen Rechenzentrum gelagert.</li>
                                <li><img src={check} className="check" alt="Windmühle"/> Mit dem Rechenzentrum der eurodata AG haben Sie das "sorglos" Paket erworben. Wir legen höchsten Wert auf den neuesten technischen Standard.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={classnames("more-panel", {'opened': open})}>
                    <div className="btn-group">
                        <div className="btn-tertiary btn" onClick={() => setOpen(!open)}>
                            {open ? 'Weniger anzeigen' : 'Mehr anzeigen'}
                        </div>
                        <button className="btn-primary"><Link to="/leistungsverzeichnis">Alle Leistungen</Link></button>
                    </div>
                </div>
            </div>
            <div className={classnames("additional", {'opened': open})}>
                <div className="plus">
                    <span/>
                    <span/>
                </div>
                <div className="min-pack">
                    <p>
                        <a href="https://edtime.de" target="blank">
                            <img src={edtime} alt=">edtime"/>
                        </a> - clevere Zeiterfassung
                    </p>
                    <h3>2,25&euro;<span> pro Mitarbeiter</span></h3>
                    <a href="https://edtime.de" className="btn btn-tertiary" target="blank">
                        Mehr über edtime
                    </a>
                </div>
            </div>
        </div>
    </section>
}

export default Price;