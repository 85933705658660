import React, { useEffect, useState } from 'react';
import anime from 'animejs';
import Navigation from './components/navigation.component';
import './styles/style.scss';
import { scrollingTop, scrollToTop } from './utils/handyFunctions';
import classnames from 'classnames';
import logo from './assets/images/LM Logo.png';
import falgLogo from './assets/images/BStBK_white.png';
import { ArrowUpward } from '@material-ui/icons';
import { Switch, Route, Link, useHistory, useLocation } from 'react-router-dom';
import Specifications from './components/specifications.component';
import Home from './components/home.component';
import AGB from './components/agb.component';
import Impressum from './components/impressum.component';
import Privacy from './components/privacy.component';
import Services from './components/services.component';
import { Phone, Pencil, Mail } from "lucide-react";

function Sidebar() {
  const location = useLocation();
  const [visible, setVisible] = useState(false); // state to control visibility

  // Scroll to #calendar when arriving on the homepage
  useEffect(() => {
    if (location.pathname === "/" && window.location.hash === "#calendar") {
      const calendarSection = document.getElementById("calendar");
      if (calendarSection) {
        setTimeout(() => {
          calendarSection.scrollIntoView({ behavior: "smooth" });
        }, 200);
      }
    }
  }, [location]);

   // Make the sidebar visible after 1-2 seconds
   useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1000); // Adjust the delay time (in milliseconds) as needed

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  return (
    <div className={`sidebar ${visible ? "visible" : ""}`}>
      {/* Phone Link */}
      <a href="tel:0049711968839809" className="sidebar-box">
        <Phone className="text-white" />
      </a>

      {/* Pencil Link (navigates to /#calendar) */}
      <Link to="/#calendar" className="sidebar-box">
        <Pencil className="text-white" />
      </Link>

      {/* Email Link */}
      <a href="mailto:info@lohnmuehle.de" className="sidebar-box">
        <Mail className="text-white" />
      </a>
    </div>
  );
}

function App() {
  const [loading] = useState(false);
  const [notHome, setNotHome] = useState(false);
  const [goToSection, setGoToSection] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname !== '/') {
      setNotHome(true);
    } else {
      const scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement;
      scrollElement.scrollTo(0, 0);
    }
    history.listen((location, action) => {
      if (history.location.pathname !== '/') {
        setNotHome(true);
      } else {
        setNotHome(false);
        document.querySelector('.go-up').classList.remove('visible');
        document
          .querySelector('.go-up')
          .removeEventListener('click', scrollingTop);
        document.removeEventListener('scroll', scrollToTop);
      }
    });
    anime({
      targets: '.next-section',
      easing: 'easeInOutQuad',
      scale: 1.1,
      loop: true,
      direction: 'alternate',
      duration: 1000,
    });
    anime({
      targets: '.next-section > path',
      easing: 'easeInOutQuad',
      translateY: 2,
      loop: true,
      direction: 'alternate',
      duration: 500,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classnames({ 'hide-scrollbar': loading })}>
      <div className={classnames('loading', { hide: !loading })}>
        <img id="logo-load" src={logo} alt="Lohnmüle logo" />
        {loading && <h3>Website wird geladen...</h3>}
      </div>
      <div className={classnames('main', { hide: loading })}>
        <Navigation
          isNotHome={notHome}
          toSection={(id) => {
            setGoToSection(id);
          }}
        />
        <Sidebar />
        <Switch>
          <Route exact path="/">
            <Home
              loaded={(val) => {
                const scrollElement =
                  window.document.scrollingElement ||
                  window.document.body ||
                  window.document.documentElement;

                if (goToSection && val) {
                  scrollElement.scrollTop =
                    document.getElementById(goToSection).offsetTop;
                }
              }}
            />
          </Route>
          <Route path="/leistungsverzeichnis" component={Specifications} />
          <Route path="/agb" component={AGB} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/datenschutz" component={Privacy} />
          <Route path="/pkc-leistungsverzeichnis" component={Services} />
        </Switch>
        {!notHome && (
          <ArrowUpward className="go-up next-section" data-target="hero" type="button" />
        )}
        {notHome && (
          <ArrowUpward className="go-up next-section" type="button" />
        )}
      </div>
      <footer>
        <div className="logos">
          <div className="logo">
            <img src={logo} alt="Lohnmühle" />
            <p>Lohnmühle</p>
          </div>
          <img src={falgLogo} className="falg-logo" alt="Falg - Bundessteuerberaterkammer" />
        </div>
        <div className="links">
          <Link to="/impressum">Impressum</Link>
          <Link to="/datenschutz">Datenschutz</Link>
          <Link to="/agb">AGB</Link>
        </div>
        <span>
          &copy;{new Date().getFullYear()} Lohnmühle GmbH alle Rechte
          vorbehalten.
        </span>
      </footer>
    </div>
  );
}

export default App;